/* global noUiSlider */
/* global hbspt */
/* global gsap */

/* eslint-disable max-len */

import gsap from 'gsap';
import Plyr from 'plyr';
import noUiSlider from 'nouislider';

(
    function ($) {
        var Site = {
            $body: $('html'),
            $searchContainer: $('.header__search'),
            $searchSuggestionsContainer: $('.header .search-suggestions'),

            $animateOnScroll: $('[data-animate]'),
            $videoAnimationOnScroll: $('[data-animate-video]'),

            $btnToTop: $('.btn--to-top'),
            $socialShare: $('.social-share'),

            $language: $('[data-language]'),

            $anchorNavigation: $('.navigation--header .anchors'),

            $productNavigation: $('.navigation--products'),

            $slider: $('.slider'),
            $sliderMain: $('.main-slider'),
            $sliderDouble: $('.double-slider'),
            $sliderGallery: $('.slider-gallery'),
            $sliderGalleryNav: $('.slider-gallery-navigation li'),
            $sliderAccessories: $('.slider-accessories'),
            $sliderSpecs: $('.specs-table-slider'),
            $sliderSpecsNav: $('.specs-table-navigation'),
            $sliderHistory: $('.history-slider'),

            $videoAnimation: $('[data-animate-video-old]'),

            $responsiveTables: $('table:not(.not-responsive)'),
            $responsiveEmbedVideos: $('iframe[src*="youtube"], iframe[src*="vimeo"]'),

            /**
             * Toggle the search form in the header.
             *
             * @param element
             */
            onToggleSearchForm: function (element) {
                var $input = $('input', this.$searchContainer);

                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-search-active');

                if ($input.length) {
                    if (this.$body.hasClass('primary-search-active')) {
                        setTimeout($.proxy(function () {
                            $input.focus();
                        }, this), 500);
                    } else {
                        $input.blur();
                    }
                }

                return false;
            },

            /**
             * Check the search form for an empty value.
             *
             * @param element
             */
            onSubmitSearchForm: function (element) {
                var $input = $('input', $(element.currentTarget));

                if ($input.val() === '' || $input.val() === $input.attr('placeholder')) {
                    alert($(element.currentTarget).attr('data-alert')); // eslint-disable-line no-alert

                    return false;
                }

                return true;
            },

            /**
             * Check the search form suggestions.
             *
             * @param element
             */
            onSearchFormSuggestions: function (element) {
                var value        = $(element.currentTarget).val();
                var context      = $('.form-search').data('context');

                if (value.length < 1) {
                    this.$searchSuggestionsContainer.html('').removeClass('active');

                    return;
                }

                $.ajax({
                    // eslint-disable-next-line max-len
                    url         : '/assets/components/simplesearch/connector.php?action=web/autosuggestions&search=' + value + '&context=' + context,
                    dataType    : 'JSON',
                    complete    : $.proxy(function (result) {
                        var $suggestions = [];

                        if (result.responseJSON.results.length) {
                            result.responseJSON.results.forEach(function (suggestion) {
                                $suggestions.push($('<li>').append($('<a>', { href: '#', title: suggestion, 'data-suggestion': suggestion }).on('click', $.proxy(function (event) {
                                    $('input', this.$searchContainer).val($(event.currentTarget).attr('data-suggestion'));

                                    $('.form-search', this.$searchContainer).submit();

                                    return false;
                                }, this)).html(suggestion)));
                            });
                        }

                        if ($suggestions.length > 0) {
                            this.$searchSuggestionsContainer.html($suggestions).addClass('active');
                        } else {
                            this.$searchSuggestionsContainer.html($suggestions).removeClass('active');
                        }
                    }, this)
                });
            },

            /**
             * Toggle the main navigation.
             *
             * @param element
             */
            onToggleNavigation: function (element) {
                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-navigation-active');

                return false;
            },

            /**
             * Toggle sub navigation in the main navigation.
             *
             * @param element
             */
            onToggleSubNavigation: function (element) {
                $(element.currentTarget).parent('li').toggleClass('subnav-active');

                return false;
            },

            /**
             * Toggle the language switch.
             */
            onToggleLanguage: function () {
                this.$language.toggleClass('active');

                return false;
            },

            /**
             * Filter slider range
             */
            initSliderRange: function () {
                var $rangeSlider = $('[data-slide-range]');

                if ($rangeSlider.length) {
                    var rangeSlider = noUiSlider.create($rangeSlider[0], {
                        start       : [
                            $rangeSlider.data('start-range'),
                            $rangeSlider.data('end-range')
                        ],
                        tooltips    : false,
                        connect     : true,
                        range       : {
                            min         : 0,
                            max         : 25
                        }
                    });

                    $rangeSlider.append($('<div class="noUi-feedback" />').append(
                        $('<span class="noUi-feedback-min" />'),
                        $('<span class="noUi-feedback-max" />')
                    ));

                    rangeSlider.on('update', function (values, handle, unencoded, tap, positions, noUiSlider) {
                        $('.noUi-feedback-min', $(noUiSlider.target)).html(values[0]);
                        $('.noUi-feedback-max', $(noUiSlider.target)).html(values[1]);
                    });

                    rangeSlider.on('set', function (values, handle, unencoded, tap, positions, noUiSlider) {
                        $('input[name="ppstart"]', $(noUiSlider.target)).val(values[0]);
                        $('input[name="ppend"]', $(noUiSlider.target)).val(values[1]).trigger('change');
                    });
                }

                return false;
            },

            /**
             * Init the anchor navigation.
             */
            initAnchorNavigation: function () {
                var $window = $(window);

                this.$anchorNavigation.each($.proxy(function (i, navigation) {
                    var $navigation         = $(navigation);
                    var $navigationItems    = $('li a', $navigation);
                    var $anchors            = [];
                    var $targets            = [];

                    $navigationItems.each($.proxy(function (ii, anchor) {
                        var $anchor  = $(anchor);
                        var headerHeight = 100;
                        var $target  = $('[data-anchor="' + $anchor.attr('href').substr(1) + '"]');

                        if ($target.length) {
                            $anchor.on('click', $.proxy(function (element) {
                                headerHeight = $('header.header').outerHeight() + 40;

                                if (Site.$body.hasClass('primary-navigation-active')) {
                                    $('.nav-toggle').trigger('click');
                                }

                                $('html, body').animate({
                                    scrollTop : $target.offset().top - headerHeight
                                }, 500);

                                $navigationItems.parent('li').removeClass('active');

                                $anchor.parent('li').addClass('active');

                                element.preventDefault();
                                return false;
                            }, this));

                            $anchors.push($anchor);
                            $targets.push($target);
                        }
                    }, this));

                    $window.on('scroll', $.proxy(function () {
                        var scrollTop = $window.scrollTop();

                        $targets.forEach($.proxy(function ($target, ii) {
                            var offset = $target.offset().top;
                            var height = $target.outerHeight(true);

                            if (scrollTop >= offset && scrollTop <= offset + height) {
                                $anchors[ii].parent('li').addClass('active');
                            } else {
                                $anchors[ii].parent('li').removeClass('active');
                            }
                        }, this));
                    }, this)).trigger('scroll');
                }, this));
            },

            /**
             * Toggle scroll to top button.
             */
            onToggleScrollToTop: function () {
                if (window.scrollY > $(window).height()) {
                    this.$btnToTop.fadeIn();
                } else {
                    this.$btnToTop.fadeOut();
                }
            },

            /**
             * Scroll to the top of the page.
             */
            onClickScrollToTop: function () {
                $('html,body').animate({
                    scrollTop : 0
                }, 500);

                return false;
            },

            /**
             * Click hash.
             *
             * @param element
             */
            onClickHash: function (element) {
                if (element.currentTarget.hash.length > 1) {
                    var $element    = $('#' + element.currentTarget.hash.substr(1));
                    var $element2   = $('[data-anchor="' + element.currentTarget.hash.substr(1) + '"]');

                    if ($(element.currentTarget).attr('data-rel') !== 'lightcase') {
                        if ($element.length) {
                            $('html, body').animate({
                                scrollTop : $element.offset().top - 100
                            }, 500);
                        } else if ($element2.length) {
                            $('html, body').animate({
                                scrollTop : $element2.offset().top - 100
                            }, 500);
                        }
                    }

                    element.preventDefault();
                }

                return false;
            },

            /**
             * Toggle social share.
             *
             * @param element.
             */
            onToggleSocialShare: function (element) {
                $(element.currentTarget).parents('.social-share').toggleClass('active');

                return false;
            },

            /**
             * Copy share URL to clipboard.
             *
             * @param element.
             */
            onCopyToClipboard: function (element) {
                var copy = false;
                var $element = $(element.currentTarget);

                $element.select();

                try {
                    copy = document.execCommand('copy');
                } catch (err) {
                    copy = false;
                }

                if (copy) {
                    $element.addClass('active').val($element.attr('data-success'));
                } else {
                    $element.removeClass('active');
                }
            },

            /**
             * Scroll to the next section.
             *
             * @param element
             */
            onScrollDown: function (element) {
                var $element = $(element.currentTarget).parents('[data-animate]').next();

                if ($element.length) {
                    $('html, body').animate({
                        scrollTop : $element.offset().top - 50
                    }, 500);
                }

                return false;
            },

            /**
             * Toggle the dropdown.
             *
             * @param element
             */
            onToggleDropdown: function (element) {
                $(element.currentTarget).parents('li').toggleClass('active');

                $('body').bind('click', this.onCloseDropDown);

                return false;
            },

            /**
             * Close the dropdown.
             *
             * @param element
             */
            onCloseDropDown: function (element) {
                $('[data-toggle-dropdown]').parents('li').removeClass('active');

                $('body').unbind('click', this.onCloseDropDown);

                if ($(element.target)[0].tagName.toLowerCase() !== 'a') {
                    return false;
                }

                return true;
            },

            /**
             * Init lightcase.
             *
             * Dirty fixed to display the HubSport forms 'normal' in a Lightcase popup.
             */
            initLightCase: function () {
                $('a[data-rel^=lightcase]').lightcase({
                    maxHeight               : 650,
                    swipe                   : true,
                    showTitle               : false,
                    showSequenceInfo        : false,
                    fullscreenModeForMobile : true,
                    onBeforeShow            : {
                        quux                   : function () {
                            var $target = $('#lightcase-content [data-render-hubspot]');

                            if ($target.length >= 1) {
                                hbspt.forms.create({
                                    portalId    : $target.attr('data-render-hubspot-poral'),
                                    formId      : $target.attr('data-render-hubspot-form'),
                                    target      : '#lightcase-content [data-render-hubspot]',
                                    onFormReady : function () {
                                        setTimeout(function () {
                                            $(window).trigger('resize');
                                        }, 10);
                                    },
                                    onFormSubmitted : function () {
                                        setTimeout(function () {
                                            $('#lightcase-case').animate({
                                                scrollTop : 0
                                            }, 500);
                                        }, 10);
                                    }
                                });
                            }
                        }
                    }
                });
            },

            /**
             * Init Lazy load (images).
             */
            initLazyLoad: function () {
                $('.lazy').Lazy();
            },

            /**
             * Check if provided element is in viewport.
             *
             * @param element
             * @param nearViewport
             */
            inViewport: function (element, nearViewport) {
                var $window = $(window);
                var $element = $(element);
                var position = $window.scrollTop();
                var visible = position + $window.height();

                if (nearViewport) {
                    return visible - 50 >= $element.offset().top;
                }

                return (visible >= ($element.offset().top + $element.outerHeight())
                    && position <= ($element.offset().top + $element.outerHeight()));
            },

            /**
             * Detect window scroll to animate blocks.
             */
            initWindowScroll: function () {
                var lastWindowScrollTop = 0;

                this.$animateOnScroll.each(function (i, element) {
                    $(element).addClass('is-loaded');
                });

                $(window).scroll($.proxy(function (event) {
                    var windowScrollTop = $(event.currentTarget).scrollTop();

                    if (windowScrollTop > 0) {
                        if (Math.abs(windowScrollTop - lastWindowScrollTop) >= 3) {
                            if (windowScrollTop < lastWindowScrollTop) {
                                this.$body.addClass('scroll-up').removeClass('scroll-down');
                            } else if (windowScrollTop > lastWindowScrollTop) {
                                this.$body.addClass('scroll-down').removeClass('scroll-up');
                            }
                        }
                    } else {
                        this.$body.removeClass('scroll-up scroll-down');
                    }

                    this.$animateOnScroll.each($.proxy(function (i, element) {
                        var $element = $(element);

                        if (!$element.hasClass('is-visible')) {
                            if (this.inViewport($element, true)) {
                                $element.addClass('is-visible');
                            }
                        }
                    }, this));

                    lastWindowScrollTop = windowScrollTop;
                }, this)).trigger('scroll');
            },

            /**
             * Init the default slider.
             */
            initSlider: function () {
                this.$slider.owlCarousel({
                    items           : 1,
                    autoplay        : false,
                    loop            : false,
                    nav             : true,
                    lazyLoad        : true
                });
            },

            /**
             * Init the main slider.
             */
            initMainSlider: function () {
                var plyrs = {};

                $('.main-slider-item', this.$sliderMain).each(function (i, slide) {
                    var $video = $('[data-player-video]', $(slide));

                    if ($video.length >= 1) {
                        plyrs['plyr-' + i] = new Plyr($video[0], {
                            muted       : true,
                            controls    : [],
                            fullscreen  : {
                                enabled     : false
                            },
                            storage     : {
                                enabled     : false,
                                key         : 'plyr'
                            }
                        });
                    }
                });

                this.$sliderMain.owlCarousel({
                    items           : 1,
                    animateIn       : 'fade-in',
                    animateOut      : 'fade-out',
                    autoplay        : true,
                    autoplayTimeout : 10000,
                    loop            : false,
                    rewind          : true,
                    nav             : true,
                    dots            : false,
                    lazyLoad        : true,
                    onInitialized    : $.proxy(function (event) {
                        this.onHandleLazyLoad(event);

                        var $slider = $(event.currentTarget);

                        $('.main-slider-item', $slider).each(function (i, slide) {
                            var $slide = $(slide);

                            if (plyrs['plyr-' + i]) {
                                if (i === 0) {
                                    $slide.addClass('video-active');
                                    $slider.trigger('stop.owl.autoplay');

                                    plyrs['plyr-' + i].play();
                                }

                                $('[data-video-toggle]', $slide).on('click', function () {
                                    if (plyrs['plyr-' + i].playing) {
                                        $slide.removeClass('video-active');
                                        $slider.trigger('start.owl.autoplay');

                                        plyrs['plyr-' + i].pause();
                                    } else {
                                        $slide.addClass('video-active');
                                        $slider.trigger('stop.owl.autoplay');

                                        plyrs['plyr-' + i].play();
                                    }
                                });
                            }
                        });
                    }, this),
                    onTranslate     : $.proxy(function (event) {
                        var $slider = $(event.currentTarget);

                        $('.main-slider-item', $slider).each(function (i, slide) {
                            var $slide = $(slide);

                            if (plyrs['plyr-' + i]) {
                                if (i === event.item.index) {
                                    $slide.addClass('video-active');
                                    $slider.trigger('stop.owl.autoplay');

                                    plyrs['plyr-' + i].play();
                                } else {
                                    plyrs['plyr-' + i].pause();
                                }
                            }
                        });
                    }, this),
                    onTranslated    : $.proxy(function (event) {
                        this.onHandleLazyLoad(event);
                    }, this)
                });
            },

            /**
             * Init the double slider.
             */
            initDoublelider: function () {
                this.$sliderDouble.owlCarousel({
                    items           : 1,
                    autoplay        : false,
                    loop            : false,
                    rewind          : true,
                    nav             : true,
                    dots            : false,
                    onInitialized   : $.proxy(function (event) {
                        this.onInitSliderCounter(event);
                        this.onHandleLazyLoad(event);
                    }, this),
                    onTranslated    : $.proxy(function (event) {
                        this.onHandleSliderCounter(event);
                        this.onHandleLazyLoad(event);
                    }, this)
                });
            },

            /**
             * Init the gallery slider.
             */
            initSliderGallery: function () {
                var slider = this.$sliderGallery.owlCarousel({
                    items           : 1,
                    animateOut      : 'fadeOut',
                    autoplay        : false,
                    loop            : false,
                    nav             : true,
                    dots            : false,
                    mouseDrag       : false,
                    touchDrag       : false,
                    onInitialized   : $.proxy(function (event) {
                        setTimeout(function () {
                            $(window).trigger('resize');
                            $(window).trigger('resize.twentytwenty');
                        }, 10);

                        this.$sliderGalleryNav.removeClass('active').eq(event.item.index).addClass('active');
                    }, this),
                    onChanged       : $.proxy(function (event) {
                        setTimeout(function () {
                            $(window).trigger('resize');
                            $(window).trigger('resize.twentytwenty');
                        }, 10);

                        this.$sliderGalleryNav.removeClass('active').eq(event.item.index).addClass('active');
                    }, this),
                    onResize        : $.proxy(function () {
                        $(window).trigger('resize.twentytwenty');
                    }, this)
                });

                $('a', this.$sliderGalleryNav).on('click', function (element) {
                    slider.trigger('to.owl.carousel', [$(element.currentTarget).parent('li').index()]);

                    return false;
                });
            },

            /**
             * Init the Accessories slider.
             */
            initSliderAccessories: function () {
                this.$sliderAccessories.owlCarousel({
                    items           : 4,
                    autoplay        : false,
                    loop            : false,
                    nav             : true,
                    dots            : false,
                    responsive      : {
                        0               : {
                            items           : 1,
                            stagePadding    : 20
                        },
                        768             : {
                            items           : 2,
                            stagePadding    : 20
                        },
                        990             : {
                            items           : 4,
                            stagePadding    : 40
                        }
                    },
                    onInitialized   : $.proxy(function (event) {
                        this.onHandleLazyLoad(event);
                    }, this),
                    onTranslated    : $.proxy(function (event) {
                        this.onHandleLazyLoad(event);
                    }, this)
                });
            },

            /**
             * Init the Specs slider.
             */
            initSliderSpecs: function () {
                var slider = this.$sliderSpecs.owlCarousel({
                    items       : 2,
                    autoplay    : false,
                    loop        : false,
                    nav         : false,
                    dots        : false,
                    responsive  : {
                        0           : {
                            items       : 1
                        },
                        768         : {
                            items       : 1,
                            mouseDrag   : true
                        },
                        990         : {
                            items       : 2,
                            mouseDrag   : false
                        }
                    },
                    onInitialized   : $.proxy(function (event) {
                        $('li', this.$sliderSpecsNav).removeClass('active').eq(event.item.index).addClass('active');
                    }, this),
                    onChanged       : $.proxy(function (event) {
                        $('li', this.$sliderSpecsNav).removeClass('active').eq(event.item.index).addClass('active');
                    }, this)
                });

                if ($('li', this.$sliderSpecsNav).length >= 3) {
                    this.$sliderSpecsNav.addClass('specs-table-navigation--scroll');
                }

                $('a', this.$sliderSpecsNav).on('click', function (element) {
                    slider.trigger('to.owl.carousel', [$(element.currentTarget).parent('li').index()]);

                    this.$sliderSpecsNav.find('li').removeClass();
                    $(this).parent().addClass('active');

                    return false;
                });

                /**
                 * Dirty fix to equal table rows height.
                 */
                $(window).resize(function () {
                    var sizes = [];
                    var $lists = $('.specs-table .list');

                    $lists.each(function (i, list) {
                        $('li', $(list)).each(function (ii, listItem) {
                            var height = $(listItem).css('height', 'auto').outerHeight();

                            if (sizes[ii] === undefined || sizes[ii] < height) {
                                sizes[ii] = height;
                            }
                        });
                    });

                    $.each(sizes, function (i, size) {
                        $('li:nth-child(' + (i + 1) + ')', $lists).css('height', size);
                    });
                }).trigger('resize');
            },

            /**
             * Init the history slider.
             */
            initHistorySlider: function () {
                this.$sliderHistory.owlCarousel({
                    autoplay        : false,
                    loop            : true,
                    nav             : true,
                    dots            : false,
                    center          : false,
                    responsive      : {
                        0               : {
                            items           : 1
                        },
                        990             : {
                            center          : true
                        }
                    },
                    onInitialized   : $.proxy(function (event) {
                        this.onInitSliderCounter(event);
                        this.onHandleLazyLoad(event);
                    }, this),
                    onTranslated    : $.proxy(function (event) {
                        this.onHandleSliderCounter(event);
                        this.onHandleLazyLoad(event);
                    }, this)
                });
            },

            /*
             * Init the slider counter.
             *
             * @param event
             */
            onInitSliderCounter: function (event) {
                if (event.relatedTarget.settings.nav) {
                    var $slider = $(event.currentTarget);
                    var $item   = $('.' + event.relatedTarget.settings.itemClass, $slider).eq(event.item.index);
                    var current = 1;
                    var total   = event.item.count;

                    if ($item.length) {
                        current = $('[data-idx]', $item).attr('data-idx');
                    }

                    $('<span class="owl-counter" />').append(
                        $('<span data-current />').html(Math.ceil(current)),
                        $('<span />').html('/'),
                        $('<span data-total />').html(total)
                    ).insertAfter($('.owl-prev', $slider));
                }
            },

            /*
             * Handle the slider counter.
             *
             * @param event
             */
            onHandleSliderCounter: function (event) {
                if (event.relatedTarget.settings.nav) {
                    var $slider = $(event.currentTarget);
                    var $item   = $('.' + event.relatedTarget.settings.itemClass, $slider).eq(event.item.index);
                    var current = 1;
                    var total   = event.item.count;

                    if ($item.length) {
                        current = $('[data-idx]', $item).attr('data-idx');
                    }

                    $('[data-current]', $slider).html(Math.ceil(current));
                    $('[data-total]', $slider).html(total);
                }
            },

            /*
             * Handle the slider lazy load images.
             *
             * @param event
             */
            onHandleLazyLoad: function (event) {
                var $lazy = $('.lazy', $(event.currentTarget)).Lazy().data('plugin_lazy');

                if ($lazy) {
                    $lazy.loadAll();
                }
            },

            /**
             * Init the ghostframe block.
             */
            initGhostframe: function () {
                function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
                    var mql = window.matchMedia(mediaQuery);

                    mql.addListener(function (e) {
                        return layoutChangedCallback(e.matches);
                    });

                    layoutChangedCallback(mql.matches);
                }

                $('.ghostframe').each(function (index, element) {
                    var duration    = 5;
                    var animations  = 3;
                    var $element    = $(element);
                    var $loader     = $('.ghostframe__loader', $element);
                    var $overlay    = $('.ghostframe__overlay', $element);
                    var $frames     = $('.ghostframe__frames', $element);
                    var $screens    = $('.ghostframe__screens', $element);

                    var timeline    = gsap.timeline({
                        defaults        : {
                            duration        : 18
                        },
                        scrollTrigger   : {
                            trigger         : $element,
                            start           : 'top top',
                            end             : '+=1800px',
                            scrub           : true,
                            pin             : $element.parents('.block')
                        }
                    });

                    if ($loader.length) {
                        timeline.to($('span', $loader), {
                            x           : 180,
                            duration    : (animations * duration) - 2
                        }, 'amimation');

                        timeline.to($loader, {
                            opacity     : 0,
                            duration    : duration,
                            delay       : (animations * duration) - 2
                        }, 'amimation');
                    }

                    if ($overlay.length) {
                        var $image = $('img', $overlay);

                        if ($image.length) {
                            installMediaQueryWatcher('(min-width: 767px)', function (matches) {
                                if (matches) {
                                    timeline.to($image, {
                                        x           : 40,
                                        duration    : (animations * duration) - 2
                                    }, 'amimation');

                                    timeline.to($image, {
                                        x           : function () {
                                            return '-=' + (($element.outerWidth() / 2) - ($image.outerWidth() / 2));
                                        },
                                        duration    : duration,
                                        delay       : (animations * duration) - 2
                                    }, 'amimation');
                                }
                            });
                        }
                    }

                    if ($frames.length) {
                        timeline.to($frames, {
                            opacity     : 0,
                            duration    : duration,
                            delay       : (animations * duration) - 2
                        }, 'amimation');

                        $('.frame', $frames).each(function (index2, frame) {
                            var $frame  = $(frame).css({ opacity : 1 });

                            if (index2 > 0) {
                                timeline.from($frame, {
                                    y           : 100,
                                    opacity     : 0,
                                    delay       : (index2 * duration) - 1,
                                    duration    : 1
                                }, 'amimation');
                            }

                            if (index2 < animations - 1) {
                                timeline.to($frame, {
                                    y           : -100,
                                    opacity     : 0,
                                    delay       : ((index2 + 1) * duration) - 2,
                                    duration    : 1
                                }, 'amimation');
                            }
                        });
                    }

                    if ($screens.length) {
                        $('.screen', $screens).each(function (index2, screen) {
                            var $screen = $(screen);

                            if (index2 > 0) {
                                timeline.from($screen, {
                                    y           : '-=125%',
                                    x           : '+=125%',
                                    opacity     : 0,
                                    delay       : (index2 * duration) - 2,
                                    duration    : 2
                                }, 'amimation');

                                installMediaQueryWatcher('(min-width: 767px)', function (matches) {
                                    if (matches) {
                                        timeline.to($screen, {
                                            x           : '-=' + ((($element.outerWidth() / 2) - ($screen.outerWidth() / 2)) / (animations - index2)),
                                            duration    : duration,
                                            delay       : (animations * duration) - 2
                                        }, 'amimation');
                                    } else {
                                        timeline.to($screen, {
                                            x           : '-=' + ((($element.outerWidth() / 2.7) - ($screen.outerWidth() / 2.7)) / (animations - index2)),
                                            duration    : duration,
                                            delay       : (animations * duration) - 2
                                        }, 'amimation');
                                    }
                                });
                            }
                        });
                    }
                });
            },

            /**
             * Old function can be deleted ASPS.
             *
             * Init the video animation block.
             */
            initVideoAnimation: function () {
                var plyr            = null;
                var $image          = $('img', this.$videoAnimation);
                var $video          = $('.video-object', this.$videoAnimation);
                var maxScale        = 1;
                var disableScroll   = false;

                if ($(window).scrollTop() <= 150) {
                    disableScroll   = true;
                }

                if ($video.length >= 1 && $image.length >= 1) {
                    plyr = new Plyr($video[0], { // eslint-disable-line no-new
                        muted       : true,
                        controls    : [],
                        fullscreen  : {
                            enabled     : false
                        },
                        storage     : {
                            enabled     : false,
                            key         : 'plyr'
                        }
                    });

                    plyr.play();

                    if (disableScroll) {
                        $('body').addClass('scroll-disabled');
                    }

                    setTimeout($.proxy(function () {
                        maxScale = Math.round($(window).width()) / Math.round($image.width()); // eslint-disable-line max-len

                        if (maxScale >= 1) {
                            $image.css({
                                opacity     : 0,
                                transform   : 'scale(' + (maxScale * 3) + ')'
                            });

                            $video.css({
                                transform   : 'scale(' + (maxScale * 3) + ')'
                            });

                            if (disableScroll) {
                                setTimeout($.proxy(function () {
                                    $('body').removeClass('scroll-disabled');
                                }, this), 1000);
                            }
                        }
                    }, this), 2000);
                }
            },

            /**
             * Init video block.
             */
            initVideoAnimationAutoplay: function () {
                var $videos = $('[data-animate-video-autoplay]');

                if ($videos.length >= 1) {
                    $videos.each($.proxy(function (index, element) {
                        var $element    = $(element);
                        var $video      = $('[data-video]', $element);
                        var player      = new Plyr($('.video-object', $video)[0], { // eslint-disable-line no-new
                            muted : true
                        });

                        $(window).scroll($.proxy(function () {
                            var scroll          = $(window).scrollTop();
                            var scrollOffset    = ($(window).height() / 2) - 100;
                            var minOffset       = $video.offset().top - scrollOffset;
                            var maxOffset       = $video.offset().top + ($video.outerHeight() - scrollOffset); // eslint-disable-line max-len

                            if (scroll >= minOffset && scroll <= maxOffset) {
                                player.play();
                            } else {
                                player.pause();
                            }
                        }, this));
                    }, this));
                }
            },

            /**
             * Init the video animation block.
             */
            initVideoAnimationOnScroll: function () {
                var $videos = $('[data-animate-video]');

                if ($videos.length >= 1) {
                    $videos.each($.proxy(function (index, element) {
                        var $element    = $(element);
                        var $image      = $('[data-image]', $element);
                        var $video      = $('[data-video]', $element);
                        var lastVideoAnimation = null;

                        new Plyr($('.video-object', $video)[0], { // eslint-disable-line no-new
                            muted       : true,
                            controls    : [],
                            fullscreen  : {
                                enabled     : false
                            },
                            storage     : {
                                enabled     : false,
                                key         : 'plyr'
                            }
                        });

                        $(window).scroll($.proxy(function () {
                            var scroll          = $(window).scrollTop();
                            var scrollOffset    = ($(window).height() / 2) - 100;
                            var minOffset       = $element.offset().top - scrollOffset;
                            var maxOffset       = $element.offset().top - 100;
                            var scrollHeight    = scroll - minOffset;
                            var maxScrollHeight = maxOffset - minOffset;
                            var percentage      = Math.round(scrollHeight / (maxScrollHeight / 100)); // eslint-disable-line max-len
                            var scale           = Math.round($(window).width() + (($(window).width() / 1.2) * 2)) / Math.round($('img', $image).width()); // eslint-disable-line max-len
                            var videoAnimation  = null;

                            if (percentage >= 0 && percentage <= 100) {
                                if (this.$body.hasClass('scroll-down')) {
                                    if (percentage >= 50) {
                                        videoAnimation = 'up';
                                    }
                                } else if (this.$body.hasClass('scroll-up')) {
                                    if (percentage <= 50) {
                                        videoAnimation = 'down';
                                    }
                                }

                                if (videoAnimation && videoAnimation !== lastVideoAnimation) {
                                    this.$body.addClass('scroll-disabled');

                                    if (videoAnimation === 'up') {
                                        $('video', $video).css({
                                            transform: 'scale(' + scale + ')'
                                        });
                                    } else if (videoAnimation === 'down') {
                                        $('video', $video).css({
                                            transform: 'scale(' + 1 + ')'
                                        });
                                    }

                                    setTimeout($.proxy(function () {
                                        this.$body.removeClass('scroll-disabled');

                                        lastVideoAnimation = videoAnimation;
                                    }, this), 2000);
                                }
                            }
                        }, this));
                    }, this));
                }
            },

            /**
             * Init the responsive tables.
             */
            initResponsiveTables: function () {
                this.$responsiveTables.each(function (i, element) {
                    $(element).wrap('<div class="table-wrapper" />');
                });
            },

            /**
             * Init the responsive embed videos.
             */
            initResponsiveEmbedVideos: function () {
                this.$responsiveEmbedVideos.each(function (i, element) {
                    $(element).wrap('<div class="video-wrapper" />').wrap('<div class="video-wrapper__inner" />');
                });
            },

            /**
             * Mail share popup.
             */
            initMailSharePopup: function () {
                var hash = window.location.hash.substring(1);

                if (this.$socialShare.length > 0) {
                    if (hash === 'mail-popup') {
                        if ($('.has-error', '#mail-popup').length !== 0) {
                            $('#gtm-share-email').trigger('click');
                        }
                    }
                }
            },

            /**
             * Init Twenty Twenty (image compare).
             */
            initTwentyTwenty: function () {
                $('.twentytwenty-container').twentytwenty({
                    no_overlay : true
                });
            },

            /**
             * Init the content toggles.
             */
            initContentToggles: function () {
                $('[data-toggle-content]').each(function (i, element) {
                    var $element    = $(element);
                    var $content    = $element.next('.block');
                    var collapsed   = $element.attr('data-toggle-content-collapsed') || 'Expand';
                    var expanded    = $element.attr('data-toggle-content-expanded') || 'Collapse';

                    if ($content.length) {
                        $content.append($('<button class="btn-close" title="' + collapsed + '">' + collapsed + '</button>'));

                        var $buttons = $([
                            $('button', $element)[0],
                            $('button', $content)[0]
                        ]);

                        $buttons.on('click', function () {
                            if ($element.hasClass('active')) {
                                $buttons.attr('title', collapsed).html(collapsed);
                            } else {
                                $buttons.attr('title', expanded).html(expanded);
                            }

                            $element.toggleClass('active');

                            $(window).trigger('resize.twentytwenty');
                        });
                    }
                });
            },

            /**
             * Handle the form to refresh the AJAX content.
             *
             * @param event
             */
            onSubmitAjaxForm: function (event) {
                var $element = $(event.currentTarget);

                $('[data-ajax-self-replace]').addClass('loading');

                $.ajax({
                    type    : 'GET',
                    url     : $element.attr('action'),
                    data    : $element.serialize(),
                    success : $.proxy(function (response) {
                        this.replaceAjaxContent(response);
                    }, this)
                });

                return false;
            },

            /**
             * Handle the form to refresh the AJAX content.
             *
             * @param event
             */
            onHandleAjaxInput: function (event) {
                $(event.currentTarget).parents('form').submit();

                return false;
            },

            /**
             * Handle the tabs to refresh the AJAX content.
             *
             * @param event
             */
            onClickAjaxTab: function (event) {
                event.preventDefault();
                var $element = $(event.currentTarget);

                $('[data-ajax-self-replace]').addClass('loading');

                $.ajax({
                    type    : 'GET',
                    url     : window.location.origin + window.location.pathname + '?region=' + $element.attr('data-tab'),
                    success : $.proxy(function (response) {
                        this.replaceAjaxContent(response);
                    }, this)
                });

                return false;
            },

            /**
             * Handle the pagination to refresh the AJAX content.
             *
             * @param element
             */
            onClickAjaxPagination: function (element) {
                var headerHeight = $('header.header').outerHeight() + 40;
                var $parentWrapper = $(element.currentTarget).parents('[data-ajax-self-replace]');
                $parentWrapper.addClass('loading');

                var url = $(element.currentTarget).attr('href');
                $.ajax({
                    type    : 'GET',
                    url     : url,
                    success : $.proxy(function (response) {
                        this.replaceAjaxContent(response);
                        $('html, body').animate({
                            scrollTop : $parentWrapper.offset().top - headerHeight
                        }, 500);
                        history.replaceState(null, null, url); // eslint-disable-line no-restricted-globals, max-len
                    }, this)
                });

                return false;
            },

            /**
             * When clicking the reset filter button, clear the form.
             */
            onClickAjaxResetFilter: function (element) {
                var $form = $(element.currentTarget).parents('[data-ajax-self-replace]').find('form');

                if ($form.length) {
                    $('input, select', $form).each(function (i, field) {
                        var $field = $(field);

                        if ($field.attr('name') === 'ppstart') {
                            $field.val(0.00);
                        } else if ($field.attr('name') === 'ppend') {
                            $field.val(25.00);
                        } else {
                            $field.val('');
                        }
                    });

                    $form.submit();
                }

                return false;
            },

            /**
             * Replace AJAX content.
             *
             * @param response
             */
            replaceAjaxContent: function (response) {
                $('[data-ajax-self-replace]').empty().append($('[data-ajax-self-replace]', response).children()).removeClass('loading');

                this.initLazyLoad();
                this.initLightCase();

                this.initSliderRange();
            },

            /**
             * Init call.
             */
            init: function () {
                this.initLazyLoad();
                this.initLightCase();
                this.initWindowScroll();
                this.initSliderRange();

                this.initAnchorNavigation();

                this.initSlider();
                this.initMainSlider();
                this.initDoublelider();
                this.initSliderGallery();
                this.initSliderAccessories();
                // this.initSliderSpecs();
                this.initHistorySlider();

                this.initVideoAnimation();
                this.initGhostframe();

                this.initVideoAnimationAutoplay();
                this.initVideoAnimationOnScroll();

                this.initResponsiveTables();
                this.initResponsiveEmbedVideos();
                this.initMailSharePopup();
                this.initTwentyTwenty();
                this.initContentToggles();
            }
        };

        $(document).on({
            click: $.proxy(Site, 'onToggleNavigation')
        }, '.nav-toggle');

        $(document).on({
            click: $.proxy(Site, 'onToggleSubNavigation')
        }, '.has-subnav .toggle-subnav');

        $(document).on({
            click: $.proxy(Site, 'onClickScrollToTop')
        }, '.btn--to-top');

        $(document).on({
            click: $.proxy(Site, 'onToggleSearchForm')
        }, '.search-toggle');

        $(document).on({
            submit: $.proxy(Site, 'onSubmitSearchForm')
        }, '.form-search');

        $(document).on({
            keyup: $.proxy(Site, 'onSearchFormSuggestions')
        }, 'header .form-search input[type="text"]');

        $(document).on({
            click: $.proxy(Site, 'onClickHash')
        }, 'a[href*="#"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSocialShare')
        }, '[data-toggle-social-share]');

        $(document).on({
            click: $.proxy(Site, 'onToggleLanguage')
        }, '[data-language-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onCopyToClipboard')
        }, '[data-copy-social-share]');

        $(document).on({
            click: $.proxy(Site, 'onScrollDown')
        }, '[data-scroll-down]');

        $(document).on({
            click: $.proxy(Site, 'onToggleDropdown')
        }, '[data-toggle-dropdown]');

        $(window).on({
            scroll: $.proxy(Site, 'onToggleScrollToTop')
        });

        $(document).on({
            submit: $.proxy(Site, 'onSubmitAjaxForm')
        }, '[data-ajax-self-replace] form');

        $(document).on({
            change: $.proxy(Site, 'onHandleAjaxInput')
        }, '[data-ajax-self-replace] input, [data-ajax-self-replace] select');

        $(document).on({
            click: $.proxy(Site, 'onClickAjaxTab')
        }, '[data-ajax-self-replace] [data-tab]');

        $(document).on({
            click: $.proxy(Site, 'onClickAjaxPagination')
        }, '[data-ajax-self-replace] .page-link');

        $(document).on({
            click: $.proxy(Site, 'onClickAjaxResetFilter')
        }, '[data-ajax-self-replace] [data-ajax-reset-filter], [data-ajax-self-replace] [data-filter-reset]');

        window.onload = function () {
            Site.init();
        };
    }(jQuery)
);
